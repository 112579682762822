require('es6-promise/auto');

window.onload = function() {
    const objMain: HTMLElement = <HTMLElement>document.getElementById('main');
    if(objMain) {
        objMain.classList.add('loaded');
    }
}

// MENU
const objmenu: HTMLElement = <HTMLElement>document.querySelector('.menu'),
    objNav: HTMLElement = <HTMLElement>document.querySelector('.nav'),
    objNavOuter: HTMLElement = <HTMLElement>document.querySelector('.nav-outer');

function noScroll(event:any) {
    event.preventDefault();
}

function toggleScroll() : any {
    document.body.classList.toggle('noScroll');
    if (document.body.classList.contains('noScroll')) {
        (<any>document).addEventListener('touchmove', noScroll, { passive: false });
        (<any>document).addEventListener('mousewheel', noScroll, { passive: false });
    } else {
        (<any>document).removeEventListener('touchmove', noScroll, { passive: false });
        (<any>document).removeEventListener('mousewheel', noScroll, { passive: false });
    }
}

if(objmenu) {
    objmenu.addEventListener("click", function () {
        toggleMenu()
    });
    objNavOuter.addEventListener("click", function () {
        if(objNav.classList.contains('active')) {
            toggleMenu();
        }
    });
    let toggleMenu = function() {
        objmenu.classList.toggle("active");
        objNav.classList.toggle("active");
        toggleScroll();
    }
}




